import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import QrReader from 'react-qr-reader';
import axios from 'axios';
import Cookie from 'js-cookie';

import Header from '../../components/layout/Header';
import Menu from '../../components/layout/Menu';
import Content from '../../components/layout/Content';
import BottomNav from '../../components/layout/BottomNav';
import Alert from '../../components/layout/Alert';
import TablePopup from './TablePopup';

import { ReactComponent as Cross } from '../../assets/cross.svg';
import { ReactComponent as Check } from '../../assets/check.svg';

import { addToAttendance } from '../../actions/scannerActions';
import { CLEAR_SCANNER_AUTO } from '../../types/scannerTypes';

import './Scanner.css';

const Scanner = () => {
    const dispatch = useDispatch();
    const scannerSelector = useSelector((state) => state.scanner);
    const authSelector = useSelector((state) => state.auth);
    const { loading, current, error } = scannerSelector;
    const { showTableScan } = authSelector;
    const [alert, setAlert] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [table, setTable] = useState('');
    const [data, setData] = useState('');
    const [cameraError, setCameraError] = useState(null);

    useEffect(() => {
        if (error) {
            setAlert({
                icon: <Cross style={{ color: '#e53e3e' }} />,
                message: error
            });
            setData('');
        } else if (current) {
            setAlert({
                icon: <Check style={{ color: '#48BB78' }} />,
                message: `${current?.customer_firstname} has been added.`
            });
        }

        return () => {
            dispatch({ type: CLEAR_SCANNER_AUTO });
        };
    }, [current, error]);

    useEffect(() => {
        if (data) {
            if (!showTableScan) {   
                dispatch(addToAttendance(data));
                setData('');
            } else {
                setShowPopup(true);
            }
        }
    }, [data]);

    const handleDismiss = () => {
        setAlert(null);
    };
    
    const handleScan = data => {
        if (data) {
            setData(data);
        }
    };

    const handleSubmitWithTable = () => {
        if (table) {
            dispatch(addToAttendance(data, table));
            setShowPopup(false);
            setData('');
            setTable('');
        }
    }

    const handleTableChange = (table) => {
        setTable(table);
    }

    const handleError = (err) => {
        async function postLog(error) {
            try {
                const { data } = await axios.post('/app_handler.php', { error_name: error.name, error_message: error.message }, { headers: { 'Wg-Method': 'LOG_ERROR', 'Wg-Key': Cookie.get('accessToken') }});
    
                setCameraError(`Something went wrong. Error code: ${data?.log_id}`);
            } catch (err) {
                console.error(err);
            }
        }

        postLog(err);
    };

    return (
        <div>
            <Header />
            <Menu />
            <Content style={{ marginTop: '85.03px' }}>
                <h1 className="scanner__title">Dun.deals Member Verification</h1>
                <p className="scanner__message">Scan customer QR Code</p>
                <div className="scanner__scanner">
                    <div style={{ marginBottom: '1rem', textAlign: 'center' }}>
                        {cameraError}
                    </div>
                    {!loading && (
                        <QrReader onScan={handleScan} onError={handleError} />
                    )}
                </div>
            </Content>
            <BottomNav />
            {alert && (<Alert icon={alert.icon} message={alert.message} dismiss={handleDismiss} />)}
            {showPopup && (<TablePopup onSubmit={handleSubmitWithTable} onChange={handleTableChange} loading={loading} table={table} />)}
        </div>
    )
}

export default Scanner;
